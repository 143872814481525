import React, { useEffect } from "react"
import { NotificationContainer } from "react-notifications"
import Header from "../../components/main/Header"
import Spinner from "../../components/partials/Spinner"
import FooterBar from "../../components/main/FooterBar"
import LeftBar from "../../components/main/LeftBar"
import { useDispatch, useSelector } from "react-redux"
import { sessionActions } from "../../actions/session.actions"
import { examActions } from "../../actions/exam.actions"
import RightBar from "../../components/main/RightBar"

const MainLayout = ({ children }) => {
    const dispatch = useDispatch()
    const pathname = window.location.pathname
    const isCalendarVisible = !pathname.includes("result")
    const { result } = useSelector((state) => state.session)
    useEffect(() => {
        if (localStorage.auth_token === "null" || localStorage.auth_token === undefined) {
            window.location = "/login"
        } else {
            dispatch(examActions.getAll())
        }
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        result || (result && result.sessionFinish && dispatch(sessionActions.clearSession()))
    }, [result])
    return (
        <div className="h-100">
            <NotificationContainer />
            <Header />
            <Spinner />

            <div className="main-content-wrapper">
                <LeftBar />
                <div className="row main no-margin position-relative">
                    <div className={`col-12 ${isCalendarVisible ? "col-lg-9" : ""}`}>{children}</div>
                    {isCalendarVisible && (
                        <div className="col-12 col-lg-3 px-lg-0 rightbar">
                            <RightBar />
                        </div>
                    )}
                    <div className={`col-12 mt-auto ${isCalendarVisible ? "col-lg-9" : ""}`}>
                        <FooterBar />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MainLayout
