import axios from "axios"
import { utils } from "../_helpers/utils"
import { base_url, axiosLoginInstance, axiosInstance } from "./axiosInstances"

export const userService = {
    generateClientToken,
    login,
    logout
}

async function generateClientToken() {
    utils.deleteStore("client_token")
    utils.deleteStore("auth_token")
    utils.deleteStore("client_token_expires_at")
    utils.deleteStore("persist:root")
    const data = {
        client_id: process.env.REACT_APP_CLIENT_ID,
        client_secret: process.env.REACT_APP_CLIENT_SECRET,
        grant_type: process.env.REACT_APP_GRANT_TYPE
    }

    try {
        const response = await axios.post(base_url + "oauth/token", data)
        utils.setStore("client_token", response.data.access_token)
        utils.setStore("client_token_expires_at", response.data.expires_in)

        return response.data
    } catch (error) {
        if (error.response.status === 401 || error.response.status === 405) {
            window.location = "/ServerError"
        }

        return error
    }
}

function login(email, password) {
    return new Promise((resolve, reject) => {
        axiosLoginInstance
            .post("login", { email, password })
            .then((res) => {
                const data = res.data
                res.data.access_token && utils.setStore("auth_token", res.data.access_token)
                resolve(data)
            })
            .catch((error) => {
                reject(error.message)
            })
    })
}

function logout() {
    return new Promise((resolve, reject) => {
        axiosInstance
            .post("logout")
            .then((res) => {
                const data = res.data
                utils.deleteStore("persist:root")
                resolve(data)
            })
            .catch((err) => {
                reject(err)
            })
    })
}

//Register service yoruma alındı
// export const register = (name, email, password, c_password) => {
//   return new Promise((resolve, reject) => {
//     let params = { name, email, password, c_password };
//     axios
//       .post(base_url + "register", params)
//       .then((res) => {
//         const data = res.data;
//         resolve(data);
//       })
//       .catch((err) => {
//         reject(err);
//       });
//   });
// };
//Yorum sonu

// export const userDetail = () => {
//     return new Promise((resolve, reject) => {
//         axios
//             .get(base_url + "users/profile")
//             .then((res) => {
//                 const data = res.data;
//                 resolve(data);
//             })
//             .catch((err) => {
//                 reject(err);
//             });
//     });
// };
