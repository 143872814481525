import { languageConstants } from "../_constants/language.constants"

export const languageActions = {
    changeLanguage
}

function changeLanguage(language) {
    return (dispatch) => {
        dispatch({
            type: languageConstants.CHANGE_LANGUAGE,
            language
        })
    }
}
