import React from "react"
import { Button, Col, Container, Modal, Row } from "react-bootstrap"
import { getTranslatedText as t } from "../../_locale"

const EmptyAnswersModal = ({ isModalOpen, setModal, acceptEmptyQuestions, getEmptyAnswerCount }) => {
    return (
        <Modal show={isModalOpen} onHide={setModal} size="md" className="empty-answers-modal" centered>
            <Modal.Header>
                <Container>
                    <Row>
                        <Col className="d-flex align-items-center" md={{ span: 8, offset: 2 }}>
                            <i className="fal fa-exclamation-circle fa-lg"></i>
                            <Modal.Title className="ml-3">{t("warning")}</Modal.Title>
                        </Col>
                    </Row>
                </Container>

                <Button
                    onClick={() => setModal()}
                    variant="dark"
                    className="d-flex rounded-circle position-absolute modal-close-icon "
                >
                    <i className="fal fa-times fa-2x"></i>
                </Button>
            </Modal.Header>
            <Modal.Body className="show-grid">
                <Container>
                    <Row>
                        <Col md={{ span: 9, offset: 2 }}>
                            <p>
                                <strong>{t("warningMessage")}</strong>
                                <br />
                                {t("emptyQuestionCount")} {getEmptyAnswerCount}
                            </p>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer className="justify-content-center">
                <Button
                    className="mr-2 p-3 rounded  w-25"
                    variant="outline-secondary"
                    size="lg"
                    onClick={() => setModal()}
                >
                    {t("back")}
                </Button>
                <Button
                    className="ml-2 p-3 rounded w-25"
                    variant="danger"
                    size="lg"
                    onClick={() => {
                        acceptEmptyQuestions()
                    }}
                >
                    {t("finishExam")}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default EmptyAnswersModal
