import React from "react"
import { useDispatch } from "react-redux"
import QuestionMark from "../../styles/img/question-mark.svg"
import Alarm from "../../styles/img/alarm.svg"
import { getTranslatedText as t } from "../../_locale"
import { utils } from "../../_helpers/utils"
import { sessionActions } from "../../actions/session.actions"
import { useHistory } from "react-router-dom"

const Exams = ({ exams, title, exam_status, noExams }) => {
    const dispatch = useDispatch()
    const history = useHistory()

    const handleStart = (e, exam_code) => {
        if (exam_status === t("continue")) {
            e.preventDefault()
            dispatch(sessionActions.start(exam_code, history))
        } else {
            history.push("/exam-guide/" + exam_code)
        }
    }

    return (
        <div className="content-block-wrap">
            <h3 className="tertiary-title">{title}</h3>
            <div className="content-block">
                <div className="table-scroll-wrap">
                    <div className="table exam-table">
                        {utils.arrayHasLength(exams) ? (
                            exams.map((exam, activeExam_key) => {
                                return (
                                    <div className="d-flex justify-content-between" key={activeExam_key}>
                                        <div>
                                            <div className="active-exam-wrap">
                                                <h4 className="exam-title">{exam.name}</h4>
                                                <p>
                                                    <span>
                                                        <img src={QuestionMark} alt="" /> {exam.question_count}{" "}
                                                        {t("question")}
                                                    </span>{" "}
                                                    <span>
                                                        <img src={Alarm} alt="" /> {exam.exam_time} {t("min")}
                                                    </span>
                                                    {exam.repeatable && (
                                                        <span className="badge badge-pill py-2">
                                                            {t("repeatableExam")}
                                                        </span>
                                                    )}
                                                </p>
                                            </div>
                                        </div>

                                        {exam_status && (
                                            <div className="d-flex align-items-center justify-content-end">
                                                <button
                                                    className="box-btn start"
                                                    onClick={(e) => {
                                                        handleStart(e, exam.exam_code)
                                                    }}
                                                >
                                                    {exam_status}
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                )
                            })
                        ) : (
                            <div className="active-exam-wrap" style={{ textAlign: "left" }}>
                                <p>{noExams}</p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Exams
