import * as React from "react"
const Warning = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={60} height={60} fill="none" {...props}>
        <path
            fill="#F56170"
            fillRule="evenodd"
            d="M2.5 53.75 30 6.25l27.5 47.5h-55Zm46.325-5L30 16.225 11.175 48.75h37.65ZM32.5 41.25h-5v5h5v-5Zm-5-15h5v10h-5v-10Z"
            clipRule="evenodd"
        />
    </svg>
)
export default Warning
