import React, { useEffect } from "react"
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom"
import Auth from "./pages/auth/Auth"
import Session from "./pages/session/Session"
import Home from "./pages/main/Home"
import Result from "./components/result/Result"
import MainLayout from "./pages/main/MainLayout"
import Results from "./pages/main/Results"
import ExamGuide from "./pages/main/ExamGuide"
import { useSelector } from "react-redux"
import NotFound from "./pages/error/NotFound"

function App() {
    const { language } = useSelector((state) => state.language)

    useEffect(() => {
        document.querySelector("html").setAttribute("lang", language)
    }, [])

    return (
        <BrowserRouter>
            <Switch>
                <Route path="/login" component={Auth} exact={true} />

                <Route
                    exact
                    path={["/", "/results", "/exam-end/:exam_code/:session_id", "/results/:exam_code/:session_id"]}
                >
                    <MainLayout>
                        <AuthenticatedRoute path="/" component={Home} exact={true} />
                        <AuthenticatedRoute path="/results" component={Results} exact={true} />
                        <AuthenticatedRoute path="/exam-end/:exam_code/:session_id" component={Result} exact={true} />
                        <AuthenticatedRoute path="/results/:exam_code/:session_id" component={Result} exact={true} />
                    </MainLayout>
                </Route>

                <AuthenticatedContinueRoute path="/exam-guide/:id" component={ExamGuide} exact={true} />
                <AuthenticatedContinueRoute path={"/exam/:id"} component={Session} exact={true} />
                <AuthenticatedRoute path={"/results/:exam_code/:session_id/answers"} component={Session} exact={true} />
                <Redirect from={["/exam-guide/", "/exam-guide"]} to="/" />

                <Redirect from={["/exam", "/exam/"]} to="/" />

                <Route component={NotFound} />
            </Switch>
        </BrowserRouter>
    )
}

const AuthenticatedRoute = ({ component: Component, ...rest }) => {
    let path = { ...rest }.location && { ...rest }.location.pathname
    return (
        <Route
            {...rest}
            render={(props) =>
                localStorage.auth_token === undefined || localStorage.auth_token === "null" ? (
                    <Redirect to={{ pathname: path ? path : "/", state: { from: props.location } }} />
                ) : (
                    <Component {...props} />
                )
            }
        />
    )
}
function AuthenticatedContinueRoute({ component: Component, ...rest }) {
    let path = { ...rest }.location && { ...rest }.location.pathname

    return (
        <Route
            {...rest}
            render={(props) =>
                localStorage.auth_token === undefined || localStorage.auth_token === "null" ? (
                    <Redirect
                        to={{
                            search: path !== null ? `?continue=${path}` : "",
                            pathname: `/login`,
                            state: { from: props.location }
                        }}
                    />
                ) : (
                    <Component {...props} />
                )
            }
        />
    )
}

export default App
