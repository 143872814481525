import { utils } from "../_helpers/utils"
import en from "./en"
import tr from "./tr"

export const languages = {
    en,
    tr
}

const getTranslatedText = (string, locale) => {
    let curLocale = utils.getPageLanguage()
    const curTranslation = languages[locale] ?? languages[curLocale] ?? languages["en"]
    return curTranslation[string] ?? string
}

export { getTranslatedText }
