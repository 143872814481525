import React, { useEffect } from "react"
import { getTranslatedText as t } from "../../_locale"
import { resultActions } from "../../actions/result.actions"
import { useDispatch, useSelector } from "react-redux"
import { utils } from "../../_helpers/utils"
import { useHistory } from "react-router-dom"
import { sessionActions } from "../../actions/session.actions"
import DisplayResultsButton from "../../components/result/DisplayResultsButton"

const Results = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const { results } = useSelector((state) => state.results)
    const spinner = useSelector((state) => state.spinner)

    const getResult = (exam_code, session_id) => {
        dispatch(sessionActions.getSessionResult(exam_code, session_id, history))
    }

    useEffect(() => {
        dispatch(resultActions.getAll())
        dispatch(sessionActions.clearSession())
    }, [])

    if (!utils.arrayHasLength(results) && !spinner) {
        return (
            <div className="main-left-wrapper ">
                <div className="content-block">
                    <h3 className="tertiary-title">{t("results")}</h3>
                    <h6>{t("noExamResult")}</h6>
                </div>
            </div>
        )
    }

    return (
        <div className="main-left-wrapper results">
            <h3 className="tertiary-title">{t("results")}</h3>
            <div className="content-block">
                <div className="user-guide-wrap">
                    <div className="accordion" id="accordionExample">
                        {results.map((result, key) => {
                            return result.repeatable ? (
                                <div className="main" key={key}>
                                    <div id={`heading${key}`}>
                                        <div
                                            className="main single-guide border collapsed"
                                            key={key}
                                            data-toggle="collapse"
                                            data-target={`#collapse${key}`}
                                            aria-expanded="true"
                                            aria-controls={`collapse${key}`}
                                        >
                                            <div className="gude-content">
                                                <p className="exam-name">
                                                    <strong>{result.name}</strong>
                                                </p>
                                            </div>

                                            {result.sessions.length > 1 ? (
                                                <div className="accordion-arrow align-items-start justify-content-end d-flex position-relative ">
                                                    {t("multipleAttempts")}{" "}
                                                </div>
                                            ) : (
                                                <DisplayResultsButton
                                                    session={result.sessions[0]}
                                                    result={result}
                                                    text={t("displayResults")}
                                                />
                                            )}
                                        </div>
                                    </div>

                                    <div
                                        id={`collapse${key}`}
                                        className="collapse"
                                        aria-labelledby={`heading${key}`}
                                        data-parent="#accordionExample"
                                    >
                                        {result.sessions.map((session, key_id) => {
                                            return (
                                                <div
                                                    className="single-guide accordion-item rounded p-3 border"
                                                    key={key_id}
                                                >
                                                    <div className="gude-content">
                                                        <p
                                                            onClick={() => getResult(result.exam_code, session.id)}
                                                            className="text-left d-flex flex-column w-100"
                                                        >
                                                            <p className="nth-session">
                                                                <strong>
                                                                    {session.key}. {t("attempt")}
                                                                </strong>
                                                            </p>
                                                            <p>{utils.getLocaleDateTime(session.starts_at)}</p>
                                                        </p>
                                                    </div>
                                                    <DisplayResultsButton
                                                        session={session}
                                                        result={result}
                                                        text={t("displayResults")}
                                                    />
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            ) : (
                                <div className="main single-guide border non-accordion">
                                    <div className="gude-content">
                                        <p className="exam-name">
                                            <strong className="bold">{result.name}</strong>
                                        </p>
                                        <p>{utils.getLocaleDateTime(result.sessions[0].ends_at)}</p>
                                    </div>

                                    <div className="d-flex align-items-start justify-content-end">
                                        <button
                                            className="btn box-btn style-3"
                                            onClick={() =>
                                                history.push(`/results/${result.exam_code}/${result.sessions[0].id}`)
                                            }
                                        >
                                            {t("displayResults")}
                                        </button>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Results
