import React from "react"
import { useHistory } from "react-router-dom/cjs/react-router-dom"
import { getTranslatedText as t } from "../../_locale"

const DisplayResultsButton = (props) => {
    const history = useHistory()
    const { result, session, text } = props
    return (
        <div className="d-flex align-items-center justify-content-end">
            <button
                className="btn box-btn style-3 ml-2"
                onClick={() => history.push(`/results/${result.exam_code}/${session.id}`)}
            >
                {text}
            </button>
        </div>
    )
}

export default DisplayResultsButton
