/* eslint-disable import/no-anonymous-default-export */
import { createStore, combineReducers, applyMiddleware, compose } from "redux"
import thunk from "redux-thunk"
import { persistReducer, persistStore } from "redux-persist"
import storage from "redux-persist/lib/storage"
import spinnerReducer from "./spinner_reducer"
import userReducer from "./user_reducer"
import examReducer from "./exam_reducer"
import sessionReducer from "./session_reducer"
import questionsControlReducer from "./questions_control_reducer"
import resultsReducer from "./results_reducer"
import languageReducer from "./language_reducer"

const persistConfig = {
    key: "root",
    storage,
    // Only persist the which you want to add to localStorage using whiteList
    whitelist: ["user", "questionsControl", "language"]
}

const rootReducer = combineReducers({
    user: userReducer,
    spinner: spinnerReducer,
    exams: examReducer,
    session: sessionReducer,
    questionsControl: questionsControlReducer,
    results: resultsReducer,
    language: languageReducer
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

export default () => {
    const middlewares = [thunk]

    const composeEnhancers =
        typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
            ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
            : compose

    const enhancer = composeEnhancers(applyMiddleware(...middlewares))

    const store = createStore(persistedReducer, enhancer)

    const persistor = persistStore(store)

    return { store, persistor }
}
