import * as React from "react"
const Check = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" {...props}>
        <g data-name="Layer 2">
            <path d="M24 0a24 24 0 1 0 24 24A24 24 0 0 0 24 0Zm0 44a20 20 0 1 1 20-20 20 20 0 0 1-20 20Z" />
            <path d="m20 29.17-6.59-6.58-2.82 2.82L20 34.83l17.41-17.42-2.82-2.82L20 29.17z" />
        </g>
    </svg>
)
export default Check
