import React from "react"
import { Container, Col, Row } from "react-bootstrap"
import { getTranslatedText as t } from "../../_locale"
import { Link } from "react-router-dom"

const NotFound = () => {
    return (
        <Container style={{ height: "100vh" }}>
            <Row className="m-0 justify-content-center align-items-center" style={{ height: "inherit" }}>
                <Col sm="12" md="10" className="d-flex flex-column justify-content-center auth_container p-0">
                    <div className="d-flex flex-column align-items-center justify-content-between auth_section p-5">
                        <h1 className="mb-5">{t("notFound")}</h1>
                        <Link to="/" className="btn box-btn btn-lg btn-letter-spacing">
                            <span className="vertical-middle ml-1 text-white">{t("home")}</span>
                        </Link>
                    </div>
                    <div className="auth_footer text-center">© {new Date().getFullYear()} ApiTwist</div>
                </Col>
            </Row>
        </Container>
    )
}

export default NotFound
