import React from "react"
import { useHistory } from "react-router-dom"
import { getTranslatedText as t } from "../../_locale"
import Home from "../../styles/icons/Home"
import Result from "../../styles/icons/Result"

const LeftBar = () => {
    let history = useHistory()

    const { pathname } = history.location

    return (
        <div className="sidebar">
            <div className="sidebar-inner">
                <ul className="sidebar-menu mt-5">
                    <li className={pathname === "/" ? "active" : ""}>
                        <a href="/">
                            <span className="menu-icon">
                                <Home />
                            </span>
                            <span className="hover-text">{t("home")}</span>
                        </a>
                    </li>
                    <li className={pathname.includes("results") ? "active" : ""}>
                        <a href="/results">
                            <span className="menu-icon">
                                <Result />
                            </span>
                            <span className="hover-text">{t("results")}</span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default LeftBar
