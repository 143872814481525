import React from "react"

const SectionHeader = ({ primaryData, secondaryData, tertiaryData, classname }) => {
    return (
        <div className={`d-flex align-items-center py-3 mb-3 ${classname}`}>
            <div className="d-flex align-items-center">
                {primaryData && <h4>{primaryData}</h4>}

                {secondaryData && (
                    <>
                        <div
                            style={{
                                width: "2px",
                                background: "lightGray",
                                height: "20px",
                                marginInline: "10px"
                            }}
                        ></div>
                        <span className="text-small text-muted">{secondaryData}</span>
                    </>
                )}

                {tertiaryData && (
                    <>
                        <div
                            style={{
                                width: "2px",
                                background: "lightGray",
                                height: "20px",
                                marginInline: "10px"
                            }}
                        ></div>
                        <span className="text-small text-muted">{tertiaryData}</span>
                    </>
                )}
            </div>
        </div>
    )
}

export default SectionHeader
