import axios from "axios"
import { utils } from "../_helpers/utils"
import { getTranslatedText as t } from "../_locale"
import { userService } from "./user.service"

export const base_url = process.env.REACT_APP_API_URL

export const axiosLoginInstance = axios.create({
    baseURL: `${base_url}api`,
    headers: {
        Accept: "application/json",
        Authorization: "Bearer " + window.localStorage.getItem("client_token")
    }
})

axiosLoginInstance.interceptors.request.use(
    (config) => {
        const token = utils.getStore("client_token")
        if (token) {
            config.headers["Authorization"] = "Bearer " + token
        }
        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)

export const axiosInstance = axios.create({
    baseURL: `${base_url}api`,
    headers: {
        Accept: "application/json"
    }
})

axiosInstance.interceptors.request.use(
    (config) => {
        const token = utils.getStore("auth_token")
        if (token) {
            config.headers["Authorization"] = "Bearer " + token
        }
        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)

axiosLoginInstance.interceptors.response.use(
    function (response) {
        return response
    },
    function (error) {
        let err
        if (error.response) {
            if (!error.config.url.includes("login") && !window.location.href.includes("login")) {
                window.location = "/login"
            } else if (error.request.status === 400) {
                err = {
                    code: error.response.status,
                    message: error.response.data.message
                }

                switch (error.response.data.message) {
                    case "Invalid username or password":
                        err = {
                            code: 400,
                            message: t("credentialError")
                        }
                        break
                    case "Authentication failed":
                        err = {
                            code: 400,
                            message: t("tryAgain")
                        }
                        break
                    default:
                        error = {
                            code: 400,
                            message: error.response.data.message
                        }
                }

                if (error.response.data.message === "Authentication failed") {
                    utils.deleteStore("persist:root")
                    userService.generateClientToken()
                }
            }
        }

        return Promise.reject(err)
    }
)

axiosInstance.interceptors.response.use(
    function (response) {
        return response
    },
    function (error) {
        let err = {
            code: error.response.status,
            message: error.response.data.message
        }

        if (error.response && error.response.data.message === "Authentication failed") {
            if (!error.config.url.includes("login") && !window.location.href.includes("login")) {
                utils.deleteStore("client_token")
                utils.deleteStore("auth_token")
                utils.deleteStore("client_token_expires_at")
                utils.deleteStore("persist:root")
                utils.createNotification("warning", t("detectedLoginDifferentBrowser"), t("warning"), 2000)
                setTimeout(() => {
                    window.location = "/login"
                }, 1000)
            }
        } else {
            utils.createNotification("warning", err.message, t("warning"), 2000)
        }

        return Promise.reject(err)
    }
)
