import { loadingConstants } from "../_constants/loading.constants"

const spinnerReducerDefaultState = false

export default (state = spinnerReducerDefaultState, action) => {
    switch (action.type) {
        case loadingConstants.LOADING:
            return action.state
        default:
            return state
    }
}
