import React, { useRef } from "react"
import { useHistory } from "react-router-dom"
import { useDispatch } from "react-redux"
import { getTranslatedText as t } from "../../_locale"
import { sessionActions } from "../../actions/session.actions"
import { utils } from "../../_helpers/utils"

const ExamStartByCode = () => {
    const dispatch = useDispatch()
    const exam_code = useRef(null)
    const history = useHistory()

    const examStartHandler = () => {
        if (exam_code.current.value.trim() == "") {
            utils.createNotification("warning", t("enterExamCodeWarning"))
            return
        }

        dispatch(sessionActions.start(exam_code.current.value, history))
    }

    return (
        <div className="content-block-wrap">
            <h3 className="tertiary-title">{t("examWithCode")}</h3>
            <div className="content-block">
                <div className="exam-wrp">
                    <form>
                        <div className="exam-code">
                            <div className="exam-code-left">
                                <input ref={exam_code} type="text" placeholder={t("examCode")} />
                            </div>
                            <button
                                type="button"
                                className="box-btn"
                                onClick={(e) => {
                                    e.preventDefault()
                                    examStartHandler()
                                }}
                            >
                                {t("start")}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default ExamStartByCode
