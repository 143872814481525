import { languageConstants } from "../_constants/language.constants"

const initialState = {
    language: "en"
}

export default (state = initialState, action) => {
    switch (action.type) {
        case languageConstants.CHANGE_LANGUAGE:
            document.querySelector("html").setAttribute("lang", action.language)
            return {
                language: action.language
            }
        default:
            return state
    }
}
