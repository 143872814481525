import { examConstants } from "../_constants/exam.constants"
import { spinner } from "./spinner"
import { examServices } from "../services/exam.services"

export const examActions = {
    getAll
}

function getAll(continueParam, history) {
    return (dispatch) => {
        dispatch(spinner(true))
        examServices
            .getAll()
            .then((data) => {
                dispatch(success(data))
                dispatch(spinner(false))

                const isAssignedExam = data?.exams?.some((exam) => exam.exam_code === continueParam)

                continueParam && history && isAssignedExam && history.push(`/exam-guide/${continueParam}`)
            })
            .catch((err) => {
                dispatch(spinner(false))
                dispatch(failure(err))
            })
    }

    function success(data) {
        return {
            type: examConstants.GET_ALL_SUCCESS,
            data
        }
    }

    function failure(error) {
        return {
            type: examConstants.GET_ALL_FAILURE,
            error
        }
    }
}
