import React, { useMemo, useState } from "react"
import Calendar from "react-calendar"
import "react-calendar/dist/Calendar.css"
import { getTranslatedText as t } from "../../_locale"
import { utils } from "../../_helpers/utils"
import { useSelector } from "react-redux"

const RightBar = () => {
    const { activeExams, upcomingExams } = useSelector((state) => state.exams)
    const { language } = useSelector((state) => state.language)
    const [value, onChange] = useState(new Date())
    let unstarted_exams = useMemo(
        () => utils.arrayHasLength(activeExams) && activeExams.filter((exam) => !exam.is_session_started),
        [activeExams]
    )

    let allUnStartedExams = utils.arrayHasLength(unstarted_exams) &&
        utils.arrayHasLength(upcomingExams) && [...unstarted_exams, ...upcomingExams]

    const getExamDays = (date) => {
        return (
            utils.arrayHasLength(allUnStartedExams) &&
            allUnStartedExams.filter(
                (unstartedExam) =>
                    utils.getDate(new Date(unstartedExam.assignment_starts_at)) === utils.getDate(date.date)
            )
        )
    }

    const higlightExamDates = (date) => {
        return getExamDays(date)?.length > 0 && "exam-day"
    }
    const higlightExamContets = (date) => {
        let examDays = getExamDays(date)
        return (
            examDays?.length > 0 && (
                <ul className="exam-hint">
                    {examDays.map((exam, key) => (
                        <li key={key}>{exam.name}</li>
                    ))}
                </ul>
            )
        )
    }

    return (
        <div className="h-100">
            <div className="right-sidebar-wrap pb-5 px-4 px-md-5 pt-lg-5 px-lg-3 px-xl-5">
                <div className="right-sidebar-block">
                    <h3 className="tertiary-title">{t("calendar")}</h3>
                    <div>
                        <Calendar
                            calendarType={utils.getCalendarType()}
                            locale={language}
                            onChange={onChange}
                            value={value}
                            tileClassName={(date) => higlightExamDates(date)}
                            tileContent={(date) => higlightExamContets(date)}
                        />
                        <div className="exam-hints"></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RightBar
