import React, { useMemo } from "react"
import { useSelector } from "react-redux"
import { getTranslatedText as t } from "../../_locale"
import Exams from "../../components/main/Exams"
import { utils } from "../../_helpers/utils"
import ExamStartByCode from "../../components/main/ExamStartByCode"

const Home = () => {
    const { activeExams, upcomingExams } = useSelector((state) => state.exams)

    let started_exams = useMemo(
        () =>
            utils.arrayHasLength(activeExams) &&
            activeExams.filter((exam) => exam.is_session_started && exam.repeatable),
        [activeExams]
    )

    let unstarted_exams = useMemo(
        () => utils.arrayHasLength(activeExams) && activeExams.filter((exam) => !exam.is_session_started),
        [activeExams]
    )
    return (
        <div className="no-padding">
            <div className="main-left-wrapper">
                {started_exams && started_exams.length > 0 && (
                    <Exams
                        exams={started_exams}
                        title={t("launchedExams")}
                        exam_status={t("continue")}
                        noExams={t("noLaunchedExam")}
                    />
                )}
                <ExamStartByCode />
                <Exams
                    exams={unstarted_exams}
                    title={t("activeExams")}
                    exam_status={t("start")}
                    noExams={t("noActiveExam")}
                />
                <Exams exams={upcomingExams} title={t("upComingExams")} noExams={t("noUpComingExams")} />
            </div>
        </div>
    )
}

export default Home
