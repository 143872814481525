/* eslint-disable import/no-anonymous-default-export */
import { resultConstants } from "../_constants"

const initialState = {
    results: [],
    result: []
}
export default (state = initialState, action) => {
    switch (action.type) {
        case resultConstants.GET_ALL_RESULTS_SUCCESS:
            return {
                results: action.results
                    .filter((perResult) => perResult.score_status !== "hide")
                    .map((result) => ({
                        ...result,
                        sessions: result.sessions
                            .map((session, key) => ({
                                ...session,
                                key: key + 1
                            }))
                            .reverse()
                    }))
            }
        case resultConstants.GET_ALL_RESULTS_FAILURE:
            return {
                ...state,
                error: action.error
            }

        default:
            return state
    }
}
