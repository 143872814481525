import { dummy_exam_result, dummy_exam_single_result } from "../_helpers/constants"
import { utils } from "../_helpers/utils"
import { axiosInstance } from "./axiosInstances"

export const sessionServices = { start, saveAnswer, finishSession, getSessionResult }

function start(code) {
    return new Promise((resolve, reject) => {
        axiosInstance
            .get(`exams/${code}/sessions/start`)
            .then((res) => {
                const data = res.data.data
                resolve(data)
            })
            .catch((err) => {
                return reject(err)
            })
    })
}

function saveAnswer(code, sessionId, answers) {
    const data = utils.handleAnswersPerQuestionId(answers)
    return new Promise((resolve, reject) => {
        axiosInstance
            .post(`exams/${code}/sessions/${sessionId}/save-answer`, data)
            .then((res) => {
                const data = res.data.success
                resolve(data)
            })
            .catch((err) => {
                return reject(err)
            })
    })
}

function finishSession(code, sessionId, answers) {
    const data = utils.objectHasLength(answers) ? utils.handleAnswersPerQuestionId(answers) : {}
    return new Promise((resolve, reject) => {
        axiosInstance
            .post(`exams/${code}/sessions/${sessionId}/finish`, data)
            .then((res) => {
                let response = utils.addAnswersSum(res.data.data)

                resolve(response)
            })
            .catch((err) => {
                return reject(err)
            })
    })
}

function getSessionResult(exam_code, session_id) {
    return new Promise((resolve, reject) => {
        axiosInstance
            .get(`exams/${exam_code}/sessions/${session_id}/result`)
            .then((res) => {
                let response = utils.addAnswersSum(res.data.data)

                resolve(response)
            })
            .catch((err) => {
                reject(err)
            })
    })
}
