import { resultConstants } from "../_constants"
import { resultServices } from "../services/result.services"
import { spinner } from "./spinner"

export const resultActions = {
    getAll
}

function getAll() {
    return (dispatch) => {
        dispatch(spinner(true))
        resultServices
            .getAll()
            .then((data) => {
                dispatch(success(data))
                dispatch(spinner(false))
            })
            .catch((err) => {
                dispatch(spinner(false))
                dispatch(failure(err))
            })
    }

    function success(results) {
        return {
            type: resultConstants.GET_ALL_RESULTS_SUCCESS,
            results
        }
    }

    function failure(error) {
        return {
            type: resultConstants.GET_ALL_RESULTS_FAILURE,
            error
        }
    }
}
