import * as React from "react"
const QuestionMark = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={60} height={60} fill="none" {...props}>
        <path
            fill="#6364CB"
            fillRule="evenodd"
            d="M5 30C5 16.2 16.2 5 30 5s25 11.2 25 25-11.2 25-25 25S5 43.8 5 30Zm27.5 10v5h-5v-5h5ZM30 50c-11.025 0-20-8.975-20-20s8.975-20 20-20 20 8.975 20 20-8.975 20-20 20ZM20 25c0-5.525 4.475-10 10-10s10 4.475 10 10c0 3.207-1.975 4.933-3.898 6.614-1.824 1.594-3.602 3.148-3.602 5.886h-5c0-4.553 2.355-6.358 4.426-7.946C33.551 28.31 35 27.198 35 25c0-2.75-2.25-5-5-5s-5 2.25-5 5h-5Z"
            clipRule="evenodd"
        />
    </svg>
)
export default QuestionMark
