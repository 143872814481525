import React, { useEffect, useMemo } from "react"
import Warning from "../../styles/icons/Warning"
import Alarm from "../../styles/icons/Alarm"
import QuestionMark from "../../styles/icons/QuestionMark"
import { getTranslatedText as t } from "../../_locale"
import { useDispatch, useSelector } from "react-redux"
import { useHistory, useParams } from "react-router-dom"
import { sessionActions } from "../../actions/session.actions"
import { utils } from "../../_helpers/utils"
import { examActions } from "../../actions/exam.actions"

const ExamGuide = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const { id } = useParams()
    const { activeExams, upcomingExams } = useSelector((state) => state.exams)
    const spinner = useSelector((state) => state.spinner)

    let unstarted_exams = useMemo(
        () => activeExams && upcomingExams && [...activeExams, ...upcomingExams],
        [activeExams, upcomingExams]
    )
    let current_exam = utils.arrayHasLength(unstarted_exams) && unstarted_exams.find((exam) => exam.exam_code === id)

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "instant"
        })

        if (!utils.arrayHasLength(unstarted_exams)) {
            dispatch(examActions.getAll())
        }
    }, [])

    useEffect(() => {
        if (!current_exam && utils.arrayHasLength(unstarted_exams) && !spinner) {
            history.push("/")
        }
    }, [current_exam, spinner, activeExams])

    return (
        <>
            {utils.objectHasLength(current_exam) && (
                <div className="container main-left-wrapper">
                    <div className="exam-guide no-padding">
                        <div className="row flex-column ml-0 mr-0 jsutify-content-center">
                            <div className="col content-block mb-5">
                                <h3 className="mb-5">{current_exam.name}</h3>
                                <div className="d-flex flex-column flex-sm-row exam-informations">
                                    <div className="w-100 d-flex flex-row flex-sm-column align-items-center justify-content-start p-3 p-lg-4 bg-light-purple rounded mr-sm-3 mr-md-5">
                                        <QuestionMark />
                                        <span className="text-center">
                                            {current_exam.question_count} {utils.capitalize(t("question"))}
                                        </span>
                                    </div>
                                    <div className="w-100 d-flex flex-row flex-sm-column align-items-center justify-content-start p-3 p-lg-4 bg-light-blue rounded my-3 my-sm-0">
                                        <Alarm />
                                        <span className="text-center">
                                            {current_exam.exam_time} {t("minutes")}
                                        </span>
                                    </div>
                                    <div className="w-100 d-flex flex-row flex-sm-column align-items-center justify-content-start p-3 p-lg-4 bg-light-red rounded ml-sm-3 ml-md-5">
                                        <Warning />
                                        <span className="text-center">
                                            {current_exam.repeatable ? t("repeatableExam") : t("nonRepeatableExam")}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            {current_exam.description && (
                                <div className="content-block py-5 mb-5 bg-white rounded">
                                    <h4 className="mb-4">{t("description")}</h4>
                                    <div className="px-3">
                                        <div dangerouslySetInnerHTML={{ __html: current_exam.description }} />
                                    </div>
                                </div>
                            )}
                            {current_exam.instruction && (
                                <div className="content-block py-5 mb-5 bg-white rounded">
                                    <h4 className="mb-4">{t("instructions")}</h4>
                                    <div className="px-3">
                                        <div dangerouslySetInnerHTML={{ __html: current_exam.instruction }} />
                                    </div>
                                </div>
                            )}

                            <div className="col mb-5 d-flex justify-content-center">
                                <button
                                    onClick={(e) => {
                                        e.preventDefault()
                                        dispatch(sessionActions.start(id, history))
                                    }}
                                    className="box-btn w-100"
                                >
                                    {t("startExam")}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default ExamGuide
