/* eslint-disable import/no-anonymous-default-export */
import { sessionConstants } from "../_constants/session.constants"

const initialState = {
    questionsControl: {}
}
export default (state = initialState, action) => {
    switch (action.type) {
        case sessionConstants.STORE_OR_DELETE_QUESTION_CONTROL:
            let isSessionExist = state.questionsControl[action.session_id] !== undefined

            let existedQuestions = isSessionExist && state.questionsControl[action.session_id]

            let indexToRemove = existedQuestions && existedQuestions.indexOf(action.question_id)

            return {
                questionsControl: {
                    [action.session_id]: isSessionExist
                        ? indexToRemove === -1
                            ? [...existedQuestions, action.question_id]
                            : [
                                  ...existedQuestions.slice(0, indexToRemove),
                                  ...existedQuestions.slice(indexToRemove + 1)
                              ]
                        : [action.question_id]
                }
            }

        default:
            return state
    }
}
