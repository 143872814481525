import React from "react"
import { useSelector } from "react-redux"
import { getTranslatedText as t } from "../../_locale"
import { utils } from "../../_helpers/utils"

const AccordionTable = ({ sessionData }) => {
    const { language } = useSelector((state) => state.language)

    return (
        <>
            {Object.values(sessionData.course_results).map((course, courseIndex) => (
                <>
                    <tbody id="accordion">
                        <tr className="bg-light table-row">
                            <td colSpan={10} className="text-left">
                                <b> {course.name[language]}</b>
                            </td>
                            <td className="text-success font-weight-bold">{t("t")}</td>
                            <td className="text-danger font-weight-bold">{t("f")}</td>
                            <td className="text-primary font-weight-bold">{t("e")}</td>
                            <td className="font-weight-bold">%</td>
                        </tr>
                    </tbody>

                    <tbody
                        className=" table-row"
                        id={`collapse${course.name[language] + courseIndex}`}
                        data-parent="#accordion"
                    >
                        {!Array.isArray(course.subjects) &&
                            Object.values(course.subjects).map((subject, subjectIndex) => (
                                <>
                                    <tr
                                        id={`collapse${course.name[language] + courseIndex}`}
                                        data-toggle="collapse"
                                        data-target={`#collapse${subject.name + courseIndex + subjectIndex}`}
                                        className="collapsed accordion table-row cursor-pointer"
                                    >
                                        <td colSpan={10} className="text-left ">
                                            {subject.name[language]}
                                        </td>
                                        <td>
                                            <b className="text-success">{subject.true_answers}</b>
                                        </td>
                                        <td>
                                            <b className="text-danger">{subject.false_answers}</b>
                                        </td>
                                        <td>
                                            <b className="text-primary">{subject.empty_answers}</b>
                                        </td>
                                        <td>
                                            <b>
                                                {Math.round(
                                                    (subject.true_answers * 100) /
                                                        (subject.true_answers +
                                                            subject.false_answers +
                                                            subject.empty_answers)
                                                )}
                                            </b>
                                            <b
                                                className={`${
                                                    !Array.isArray(subject.outcomes) ? "accordion-arrow" : ""
                                                }`}
                                            ></b>
                                        </td>
                                    </tr>

                                    <>
                                        {!Array.isArray(subject.outcomes) &&
                                            Object.values(subject.outcomes).map((outcome, outcomeIndex) => (
                                                <tr
                                                    id={`collapse${subject.name + courseIndex + subjectIndex}`}
                                                    className="collapse"
                                                >
                                                    <td colSpan={10} className="text-left c-text-secondary">
                                                        {outcome.name[language]}
                                                    </td>
                                                    <td>
                                                        <b className="text-success">{outcome.true_answers}</b>
                                                    </td>
                                                    <td>
                                                        <b className="text-danger">{outcome.false_answers}</b>
                                                    </td>
                                                    <td>
                                                        <b className="text-primary">{outcome.empty_answers}</b>
                                                    </td>
                                                    <td>
                                                        <b className="accordion-arrow visibility-hidden">
                                                            {Math.round(
                                                                (outcome.true_answers * 100) /
                                                                    (outcome.true_answers +
                                                                        outcome.false_answers +
                                                                        outcome.empty_answers)
                                                            )}
                                                        </b>
                                                    </td>
                                                </tr>
                                            ))}
                                    </>
                                </>
                            ))}
                    </tbody>
                </>
            ))}
        </>
    )
}

export default AccordionTable
