import React, { useEffect, useMemo } from "react"
import { NotificationContainer } from "react-notifications"
import { useDispatch, useSelector } from "react-redux"
import ExamHeader from "../../components/session/ExamHeader"
import LeftOptionBar from "../../components/session/LeftOptionBar"
import Question from "../../components/session/Question"
import { utils } from "../../_helpers/utils"
import { getTranslatedText as t } from "../../_locale"
import { useHistory, useParams } from "react-router-dom"
import { sessionActions } from "../../actions/session.actions"
import LeftBar from "../../components/main/LeftBar"

const Session = () => {
    const history = useHistory()
    const dispatch = useDispatch()
    const { exam_code, session_id } = useParams()

    const { session, selectedCourseId, leftBarFilter, question_show, answers, result } = useSelector(
        (state) => state.session
    )

    const { questionsControl } = useSelector((state) => state.questionsControl)
    const { language } = useSelector((state) => state.language)
    const questionsToShowAfterCourseFilters = useMemo(
        () =>
            utils.objectHasLength(session)
                ? selectedCourseId === ""
                    ? utils.getAllQuestions(session.courses)
                    : session.courses.find((course) => course.id === selectedCourseId).questions
                : [],
        [session, selectedCourseId]
    )

    const filteredQuestions = useMemo(
        () =>
            utils.arrayHasLength(questionsToShowAfterCourseFilters) &&
            utils.filterQuestions(leftBarFilter, questionsToShowAfterCourseFilters, questionsControl),
        [questionsToShowAfterCourseFilters, leftBarFilter, questionsControl]
    )

    const question =
        utils.arrayHasLength(filteredQuestions) &&
        filteredQuestions.find((filteredQuestion, key) => filteredQuestion.questionId === question_show)

    useEffect(() => {
        utils.arrayHasLength(filteredQuestions) &&
            dispatch(sessionActions.changeShowQuestion(filteredQuestions[0].questionId))
    }, [leftBarFilter, selectedCourseId])

    const questionIndex =
        question !== undefined
            ? questionsToShowAfterCourseFilters.findIndex((q) => q.questionId === question_show)
            : undefined

    let questionsControlOfSession = useMemo(
        () =>
            question &&
            utils.objectHasLength(questionsControl) &&
            questionsControl[session.session_id] !== undefined &&
            questionsControl[session.session_id].length > 0 &&
            questionsControl[session.session_id],
        [questionsControl, question]
    )

    const showExamInfo = () => {
        utils.createNotification("info", session.instruction, session.name + t("about"), 10000)
    }

    useEffect(() => {
        if (!utils.checkPageInResult() && !utils.objectHasLength(session)) history.push("/")
    }, [session])

    useEffect(() => {
        if (utils.checkPageInResult()) {
            if (!utils.objectHasLength(result) && !utils.objectHasLength(session)) {
                dispatch(sessionActions.getSessionResult(exam_code, session_id))
            }
        }
    }, [result, session])

    useEffect(() => {
        utils.getFirstPath() === "exam" &&
            utils.objectHasLength(answers) &&
            dispatch(sessionActions.saveAnswer(session.exam_code, session.session_id, answers))
    }, [question_show, selectedCourseId, leftBarFilter])

    useEffect(() => {
        if (utils.getFirstPath() === "exam") {
            if (leftBarFilter === "EMPTY" && filteredQuestions.length === 0 && utils.objectHasLength(answers)) {
                dispatch(sessionActions.saveAnswer(session.exam_code, session.session_id, answers))
            }
        }
    }, [leftBarFilter, filteredQuestions, answers])

    return (
        <div className="exam-page">
            {utils.objectHasLength(session) && (
                <>
                    <NotificationContainer />
                    <ExamHeader />

                    <div className="outer-all-wrap">
                        {utils.objectHasLength(session) && session.session_ends_at !== null && <LeftBar />}
                        <main>
                            <div
                                className={`main-content-wrapper ${
                                    utils.objectHasLength(session) && session.session_ends_at !== null
                                        ? "exam-answers"
                                        : "exam-pge"
                                } `}
                            >
                                <div className="exam-question-wrapper">
                                    <div
                                        className="input-inside exam-name small-screen-exam-title"
                                        onClick={showExamInfo}
                                    >
                                        <h2>{session.name[language]}</h2>
                                    </div>
                                    <LeftOptionBar
                                        questionsToShowAfterCourseFilters={questionsToShowAfterCourseFilters}
                                        filteredQuestions={filteredQuestions}
                                        questionsControlOfSession={questionsControlOfSession}
                                    />
                                    <Question
                                        questions={filteredQuestions}
                                        question={question}
                                        questionIndex={questionIndex}
                                        questionsControlOfSession={questionsControlOfSession}
                                        questionsToShowAfterCourseFilters={questionsToShowAfterCourseFilters}
                                    />
                                </div>
                            </div>
                        </main>
                    </div>
                </>
            )}
        </div>
    )
}

export default Session
