/* eslint-disable import/no-anonymous-default-export */
import { examConstants } from "../_constants/exam.constants"

const initialState = {
    activeExams: [],
    upcomingExams: []
}

export default (state = initialState, action) => {
    switch (action.type) {
        case examConstants.GET_ALL_SUCCESS:
            return {
                activeExams: action.data.exams,
                upcomingExams: action.data.upcoming_exams
            }
        case examConstants.GET_ALL_FAILURE:
            return {
                error: action.error
            }
        default:
            return state
    }
}
