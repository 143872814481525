import React, { useMemo } from "react"
import { useSelector } from "react-redux"
import DoughnutChart from "../partials/charts/DoughnutChart"
import BarChart from "../partials/charts/BarChart"

const ExamResultCard = ({
    sessionData,
    examHasMultipleLessons,
    lessonHasPreviousSessions,
    comparisonWithPreviousSession
}) => {
    const { language } = useSelector((state) => state.language)

    return (
        <div className={`col col-12 ${comparisonWithPreviousSession ? "col-lg-8" : ""}  my-5 my-lg-0`}>
            <div className="row d-flex flex-column flex-sm-row align-items-center">
                <div
                    className="col-12 col-md-6 d-flex justify-content-center order-2 order-md-1"
                    style={{ height: "250px", width: "100%" }}
                >
                    <BarChart
                        dataSet={[sessionData.true_answers, sessionData.false_answers, sessionData.empty_answers]}
                    />
                </div>

                <div className="col-12 col-md-6 d-flex flex-column justify-content-center align-items-center order-1 order-md-2">
                    <div className="d-flex flex-column align-items-center" style={{ height: "250px", width: "100%" }}>
                        <DoughnutChart data={sessionData} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ExamResultCard
