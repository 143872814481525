import { userConstants } from "../_constants"

const loginReducerDefaultState = {
    loading: false,
    loggedIn: null
}

export default (state = loginReducerDefaultState, action) => {
    switch (action.type) {
        case userConstants.LOGIN_REQUEST:
            return {
                loading: true
            }
        case userConstants.LOGIN_SUCCESS:
            return {
                user: action.payload.user,
                user_token: action.payload.token,
                loading: false
            }
        case userConstants.LOGIN_FAILURE:
            return {
                loading: false,
                error: action.error
            }
        case userConstants.GETUSER_REQUEST:
            return {
                loading: true
            }
        case userConstants.GETUSER_SUCCESS:
            return {
                user: action.res,
                loading: false
            }
        case userConstants.GETUSER_FAILURE:
            return {
                loading: false,
                error: action.error
            }
        case userConstants.LOGOUT:
            return {
                loading: false,
                loggedIn: false
            }
        case userConstants.CLEAR_ERRORS:
            let { error, ...newState } = state
            return {
                ...newState
            }
        default:
            return state
    }
}
