import React from "react"
import { useDispatch } from "react-redux"
import { sessionActions } from "../../actions/session.actions"
import { getTranslatedText as t } from "../../_locale"

const DisplayAnswersButton = ({ exam_code, session_id, history }) => {
    const dispatch = useDispatch()
    return (
        <button
            className="btn box-btn btn-lg"
            onClick={() => {
                dispatch(sessionActions.getSessionResult(exam_code, session_id, history))
            }}
        >
            {t("displayAnswers")}
        </button>
    )
}

export default DisplayAnswersButton
