import React from "react"

const EvaluationCard = ({ text, value, className, children, childClassName }) => {
    return (
        <div className={`d-flex rounded text-center ${className}`}>
            <div className={`d-flex align-self-center p-3 rounded mr-3 ${childClassName}`}>{children}</div>
            <div className="text-left">
                <h6>{value}</h6>
                <span className="text-small text-muted">{text}</span>
            </div>
        </div>
    )
}

export default EvaluationCard
