import { axiosInstance } from "./axiosInstances"

export const resultServices = {
    getAll
}

function getAll() {
    return new Promise((resolve, reject) => {
        axiosInstance
            .get("exams/results")
            .then((res) => {
                const data = res.data.data

                resolve(data)
            })
            .catch((err) => {
                reject(err)
            })
    })
}
