import { userService } from "../services/user.service"
import { userConstants } from "../_constants"
import { examActions } from "./exam.actions"

export const userActions = {
    login,
    logout,
    clearErrors
}

function login(email, password, continueParam, history) {
    return (dispatch) => {
        dispatch(request())
        userService.login(email, password).then(
            (res) => {
                dispatch(success(res))
                continueParam ? dispatch(examActions.getAll(continueParam, history)) : history.push("/")
            },
            (error) => {
                dispatch(failure(error))
            }
        )
    }
    function request() {
        return { type: userConstants.LOGIN_REQUEST }
    }

    function success(res) {
        return {
            type: userConstants.LOGIN_SUCCESS,
            payload: { user: res.user, token: res.access_token }
        }
    }

    function failure(error) {
        return { type: userConstants.LOGIN_FAILURE, error }
    }
}

function logout() {
    userService.logout()

    return (dispatch) => {
        dispatch(success())
    }

    function success() {
        return { type: userConstants.LOGOUT }
    }
}

function clearErrors() {
    return {
        type: userConstants.CLEAR_ERRORS
    }
}

// function profile() {
//     return (dispatch) => {
//         dispatch(profileRequest());
//         userDetail().then(
//             (res) => {
//                 dispatch(profileSuccess(res));
//             },
//             (error) => {
//                 dispatch(profileFailure(error));
//             }
//         );
//     };
//     function profileRequest() {
//         return { type: userConstants.GETUSER_REQUEST };
//     }
//     function profileSuccess(res) {
//         return { type: userConstants.GETUSER_SUCCESS, res };
//     }
//     function profileFailure(error) {
//         return { type: userConstants.GETUSER_FAILURE, error };
//     }
// }
