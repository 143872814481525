import { userService } from "../services/user.service"

export const tokenActions = {
    getClientToken
}

function getClientToken() {
    return () => {
        userService.generateClientToken()
    }
}
