import * as React from "react"
const Percent = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        data-name="Layer 1"
        viewBox="0 0 24 24"
        {...props}
        fill={props.fill}
    >
        <path d="M7.758 10.758a3 3 0 1 0-3-3 3.003 3.003 0 0 0 3 3Zm0-4a1 1 0 1 1-1 1 1 1 0 0 1 1-1Zm8.484 6.484a3 3 0 1 0 3 3 3.003 3.003 0 0 0-3-3Zm0 4a1 1 0 1 1 1-1 1 1 0 0 1-1 1Zm3.465-12.949a1 1 0 0 0-1.414 0l-14 14a1 1 0 1 0 1.414 1.414l14-14a1 1 0 0 0 0-1.414Z" />
    </svg>
)
export default Percent
