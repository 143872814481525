import React from "react"

const CreateFormElement = ({ inputType, value, onChange, formErrors }) => {
    const createFormElement = (inputType) => {
        switch (inputType.element) {
            case "input":
                return (
                    <div className="form-group">
                        <label htmlFor="user-name">{inputType.label}</label>
                        <input
                            id={inputType.name}
                            type={inputType.type}
                            className="form-control"
                            name={inputType.name}
                            onChange={onChange}
                            value={value}
                        />
                        {formErrors && formErrors[inputType.name.toLowerCase()] && (
                            <span className={`form-error ${formErrors[inputType.name.toLowerCase()] ? "error" : ""}`}>
                                {formErrors[inputType.name.toLowerCase()]}
                            </span>
                        )}
                    </div>
                )
            default:
        }
    }

    return <>{createFormElement(inputType)}</>
}

export default CreateFormElement
