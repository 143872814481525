import React from "react"
import { Line } from "react-chartjs-2"
import { Chart as ChartJS, LineElement, PointElement, CategoryScale, LinearScale, Tooltip, Legend } from "chart.js"
import { getTranslatedText as t } from "../../_locale"
ChartJS.register(LineElement, PointElement, CategoryScale, LinearScale, Tooltip, Legend)

const CompareCard = () => {
    const data = {
        labels: ["10.10.2024", "11.01.2024", "12.02.2024"],
        datasets: [
            {
                label: t("score"),
                data: [60, 80, 88],
                borderColor: "rgba(75, 192, 192)",
                backgroundColor: "rgba(75, 192, 192 , 0.6)",
                tension: 0.4,
                fill: true
            }
        ]
    }
    const options = {
        plugins: {
            legend: {
                display: false
            }
        },
        scale: {
            y: {
                min: 0,
                max: 100
            }
        }
    }
    return <Line data={data} options={options} />
}

export default CompareCard
