import * as React from "react"
const Alarm = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={60} height={60} fill="none" {...props}>
        <path
            fill="#0EAADF"
            fillRule="evenodd"
            d="m19.861 8.602-3.203-3.839L5.14 14.372l3.203 3.84 11.518-9.61Zm23.48-3.838 11.519 9.61-3.203 3.839-11.518-9.609 3.203-3.84Zm-12.09 15.473H27.5v15l11.874 7.125 1.875-3.075-10-5.925V20.237Zm-1.25-10c-12.426 0-22.5 10.075-22.5 22.5s10.074 22.5 22.5 22.5c12.424 0 22.5-10.075 22.5-22.5s-10.076-22.5-22.5-22.5Zm-17.5 22.5c0 9.65 7.85 17.5 17.5 17.5s17.5-7.85 17.5-17.5-7.85-17.5-17.5-17.5-17.5 7.85-17.5 17.5Z"
            clipRule="evenodd"
        />
    </svg>
)
export default Alarm
