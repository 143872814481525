import { getTranslatedText as t } from "../_locale"
export const ANS_OPTIONS = ["A", "B", "C", "D", "E", "F", "G"]

export const TRUE_FALSE_OPTIONS = [
    { key: "T", value: "True" },
    { key: "F", value: "False" }
]

export const FILTER_OPTIONS = {
    ALL: t("all"),
    FAVORITE: t("control"),
    EMPTY: t("empty"),
    FULL: t("answered"),
    CONTROL: t("control"),
    WRONG: t("wrong")
}

export const dummy_exam_result = {
    course_results: {
        student_count_joined_exam: 23765,
        rank: 4193,
        succeed_percentage: 75,
        score: 68,
        max_score: 100,
        true_answers: 27,
        false_answers: 13,
        empty_answers: 0,
        avarage_net: 55.67,
        1: {
            name: {
                tr: "Türkçe ",
                en: "Turkish"
            },
            success_rate: 80,
            true_answers: 12,
            false_answers: 8,
            empty_answers: 0,
            total_net: 16
        },
        2: {
            name: {
                tr: "Tarih",
                en: "History"
            },
            success_rate: 60,
            true_answers: 7,
            false_answers: 3,
            empty_answers: 0,
            total_net: 6
        },
        3: {
            name: {
                tr: "İngilizce",
                en: "English"
            },
            success_rate: 73.3,
            true_answers: 8,
            false_answers: 2,
            empty_answers: 0,
            total_net: 7.33
        }
    },
    courses: [
        {
            id: 1,
            name: {
                tr: "Türkçe 5",
                en: "Turkish"
            },
            question_show_type: "in_order",
            true_answers: 12,
            false_answers: 8,
            empty_answers: 0,
            score: 30,
            success_rate: 80,
            max_score: 50,
            subjects: [
                {
                    id: 1,
                    name: "Yazma",
                    success_rate: 50,
                    true_answers: 10,
                    false_answers: 15,
                    empty_answers: 5,
                    outcomes: [
                        {
                            name: "Şiir yazar.",
                            true_answers: 1,
                            false_answers: 0,
                            empty_answers: 0,
                            success_rate: 80
                        },
                        {
                            name: "Bilgilendirici metin yazar.",
                            true_answers: 1,
                            false_answers: 0,
                            empty_answers: 0,
                            success_rate: 80
                        },
                        {
                            name: "Hikâye edici metin yazar.",
                            true_answers: 1,
                            false_answers: 0,
                            empty_answers: 0,
                            success_rate: 80
                        },
                        {
                            name: "Yazma stsuccess_ratejilerini uygular.",
                            true_answers: 1,
                            false_answers: 0,
                            empty_answers: 0,
                            success_rate: 80
                        },
                        {
                            name: "Anlatımı desteklemek için grafik ve tablo kullanır.",
                            true_answers: 1,
                            false_answers: 0,
                            empty_answers: 0,
                            success_rate: 80
                        }
                    ]
                },
                {
                    id: 2,
                    name: "Dinleme, İzleme",
                    success_rate: 50,
                    true_answers: 10,
                    false_answers: 15,
                    empty_answers: 5,
                    outcomes: [
                        {
                            name: "Dinlediklerinde/izlediklerinde geçen olayların gelişimi ve sonucu hakkında tahminde bulunur.",
                            true_answers: 1,
                            false_answers: 0,
                            empty_answers: 0,
                            success_rate: 80
                        },
                        {
                            name: "Dinlediklerinde/izlediklerinde geçen bilmediği kelimelerin anlamını tahmin eder.",
                            true_answers: 1,
                            false_answers: 0,
                            empty_answers: 0,
                            success_rate: 80
                        },
                        {
                            name: " Dinlediklerini/izlediklerini özetler.",
                            true_answers: 1,
                            false_answers: 0,
                            empty_answers: 0,
                            success_rate: 80
                        },
                        {
                            name: "Dinledikleri/izlediklerine yönelik sorulara cevap verir.",
                            true_answers: 0,
                            false_answers: 1,
                            empty_answers: 0,
                            success_rate: 0
                        },
                        {
                            name: "Dinlediklerinin/izlediklerinin konusunu tespit eder.",
                            true_answers: 0,
                            false_answers: 1,
                            empty_answers: 0,
                            success_rate: 0
                        },
                        {
                            name: "Dinlediklerinin/izlediklerinin ana fikrini/ana duygusunu tespit eder.",
                            true_answers: 0,
                            false_answers: 1,
                            empty_answers: 0,
                            success_rate: 0
                        },
                        {
                            name: "Dinlediklerine/izlediklerine yönelik farklı başlıklar önerir.",
                            true_answers: 0,
                            false_answers: 1,
                            empty_answers: 0,
                            success_rate: 0
                        },
                        {
                            name: "Dinlediği/izlediği hikâye edici metinleri canlandırır.",
                            true_answers: 0,
                            false_answers: 1,
                            empty_answers: 0,
                            success_rate: 0
                        },
                        {
                            name: "Dinlediklerinde/izlediklerinde tutarlılığı sorgular.",
                            true_answers: 0,
                            false_answers: 1,
                            empty_answers: 0,
                            success_rate: 0
                        },
                        {
                            name: "Dinledikleriyle/izledikleriyle ilgili görüşlerini bildirir.",
                            true_answers: 0,
                            false_answers: 1,
                            empty_answers: 0,
                            success_rate: 0
                        }
                    ]
                },
                {
                    id: 3,
                    name: "Akıcı Okuma",
                    success_rate: 50,
                    true_answers: 0,
                    false_answers: 15,
                    empty_answers: 5,
                    outcomes: [
                        {
                            name: "Noktalama işaretlerine dikkat ederek sesli ve sessiz okur.",
                            true_answers: 0,
                            false_answers: 1,
                            empty_answers: 0,
                            success_rate: 0
                        }
                    ]
                }
            ]
        },
        {
            id: 2,
            name: {
                tr: "Tarih",
                en: "History"
            },
            question_show_type: "in_order",
            true_answers: 7,
            false_answers: 3,
            empty_answers: 0,
            success_rate: 60,
            max_score: 25,
            score: 18,
            subjects: [
                {
                    id: 1,
                    name: "Millî Bir Destan: Ya İstiklal Ya Ölüm",
                    success_rate: 50,
                    true_answers: 10,
                    false_answers: 15,
                    empty_answers: 5,
                    outcomes: [
                        {
                            name: "Millî Mücadele Dönemi’nde Batı Cephesi’nde meydana gelen gelişmeleri kavrar.",
                            true_answers: 1,
                            false_answers: 0,
                            empty_answers: 0,
                            success_rate: 80
                        },
                        {
                            name: "Millî Mücadele’nin zor bir döneminde Maarif Kongresi yapan Atatürk’ün, millî ve çağdaş eğitime verdiği önemi kavrar.",
                            true_answers: 1,
                            false_answers: 0,
                            empty_answers: 0,
                            success_rate: 80
                        },
                        {
                            name: "Türk milletinin millî birlik, beraberlik ve dayanışmasının bir örneği olarak Tekalif-i Millîye Emirleri doğrultusunda yapılan uygulamaları analiz eder.",
                            true_answers: 1,
                            false_answers: 0,
                            empty_answers: 0,
                            success_rate: 80
                        },
                        {
                            name: "Sakarya Meydan Savaşı’nın kazanılmasında ve Büyük Taarruz’un başarılı olmasında Mustafa Kemal’in rolüne ilişkin çıkarımlarda bulunur.",
                            true_answers: 1,
                            false_answers: 0,
                            empty_answers: 0,
                            success_rate: 80
                        },
                        {
                            name: "Lozan Antlaşması’nın sağladığı kazanımları analiz eder.",
                            true_answers: 1,
                            false_answers: 0,
                            empty_answers: 0,
                            success_rate: 80
                        },
                        {
                            name: "Millî Mücadele Dönemi’nin siyasi, sosyal ve kültürel olaylarının sanat ve edebiyat ürünlerine yansımalarına kanıtlar gösterir.",
                            true_answers: 1,
                            false_answers: 0,
                            empty_answers: 0,
                            success_rate: 80
                        }
                    ]
                },
                {
                    id: 2,
                    name: "Atatürkçülük ve Çağdaşlaşan Türkiye",
                    success_rate: 10,
                    true_answers: 1,
                    false_answers: 3,
                    empty_answers: 0,
                    outcomes: [
                        {
                            name: "Çağdaşlaşan Türkiye’nin temeli olan Atatürk ilkelerini açıklar.",
                            true_answers: 1,
                            false_answers: 0,
                            empty_answers: 0,
                            success_rate: 80
                        },
                        {
                            name: "Siyasi alanda meydana gelen gelişmeleri kavrar.",
                            true_answers: 0,
                            false_answers: 1,
                            empty_answers: 0,
                            success_rate: 0
                        },
                        {
                            name: "Eğitim ve kültür alanında yapılan inkılapları ve gelişmeleri kavrar.",
                            true_answers: 0,
                            false_answers: 1,
                            empty_answers: 0,
                            success_rate: 0
                        },
                        {
                            name: "Hukuk alanında meydana gelen gelişmelerin toplumsal hayata yansımalarını kavrar.",
                            true_answers: 0,
                            false_answers: 1,
                            empty_answers: 0,
                            success_rate: 0
                        }
                    ]
                }
            ]
        },
        {
            id: 3,
            name: {
                tr: "İngilizce",
                en: "English"
            },
            question_show_type: "in_order",
            true_answers: 8,
            false_answers: 2,
            empty_answers: 0,
            success_rate: 73.3,
            score: 20,
            max_score: 25,
            subjects: [
                {
                    id: 1,
                    name: "Communication",
                    success_rate: 50,
                    true_answers: 10,
                    false_answers: 15,
                    empty_answers: 5,
                    outcomes: [
                        {
                            name: "Expressing concern and sympathy.",
                            true_answers: 1,
                            false_answers: 0,
                            empty_answers: 0,
                            success_rate: 80
                        },
                        {
                            name: "Handling phone conversations.",
                            true_answers: 1,
                            false_answers: 0,
                            empty_answers: 0,
                            success_rate: 80
                        },
                        {
                            name: "Making simple inquiries.",
                            true_answers: 1,
                            false_answers: 0,
                            empty_answers: 0,
                            success_rate: 80
                        },
                        {
                            name: "Talking about plans.",
                            true_answers: 1,
                            false_answers: 0,
                            empty_answers: 0,
                            success_rate: 80
                        },
                        {
                            name: "Expressing concern and sympathy.",
                            true_answers: 1,
                            false_answers: 0,
                            empty_answers: 0,
                            success_rate: 80
                        },
                        {
                            name: "Handling phone conversations.",
                            true_answers: 1,
                            false_answers: 0,
                            empty_answers: 0,
                            success_rate: 80
                        },
                        {
                            name: "Making simple inquiries.",
                            true_answers: 1,
                            false_answers: 0,
                            empty_answers: 0,
                            success_rate: 80
                        },
                        {
                            name: "Talking about plans.",
                            true_answers: 1,
                            false_answers: 0,
                            empty_answers: 0,
                            success_rate: 80
                        }
                    ]
                },
                {
                    id: 2,
                    name: "Adventures",
                    success_rate: 13,
                    true_answers: 10,
                    false_answers: 15,
                    empty_answers: 5,
                    outcomes: [
                        {
                            name: "Expressing preferences.",
                            true_answers: 0,
                            false_answers: 1,
                            empty_answers: 0,
                            success_rate: 0
                        },
                        {
                            name: "Giving explanations/reasons.",
                            true_answers: 0,
                            false_answers: 1,
                            empty_answers: 0,
                            success_rate: 0
                        }
                    ]
                }
            ]
        }
    ]
}

export const dummy_exam_single_result = {
    course_results: {
        student_count_joined_exam: 23765,
        rank: 4193,
        succeed_percentage: 75,
        score: 68,
        max_score: 100,
        true_answers: 27,
        false_answers: 13,
        empty_answers: 0,
        avarage_net: 55.67,
        1: {
            name: {
                tr: "Türkçe ",
                en: "Turkish"
            },
            success_rate: 80,
            true_answers: 12,
            false_answers: 8,
            empty_answers: 0,
            total_net: 16
        }
    },
    courses: [
        {
            id: 1,
            name: {
                tr: "Türkçe 5",
                en: "Turkish"
            },
            question_show_type: "in_order",
            true_answers: 12,
            false_answers: 8,
            empty_answers: 0,
            score: 30,
            success_rate: 80,
            max_score: 50,
            subjects: [
                {
                    id: 1,
                    name: "Yazma",
                    success_rate: 50,
                    true_answers: 10,
                    false_answers: 15,
                    empty_answers: 5,
                    outcomes: [
                        {
                            name: "Şiir yazar.",
                            true_answers: 1,
                            false_answers: 0,
                            empty_answers: 0,
                            success_rate: 80
                        },
                        {
                            name: "Bilgilendirici metin yazar.",
                            true_answers: 1,
                            false_answers: 0,
                            empty_answers: 0,
                            success_rate: 80
                        },
                        {
                            name: "Hikâye edici metin yazar.",
                            true_answers: 1,
                            false_answers: 0,
                            empty_answers: 0,
                            success_rate: 80
                        },
                        {
                            name: "Yazma stsuccess_ratejilerini uygular.",
                            true_answers: 1,
                            false_answers: 0,
                            empty_answers: 0,
                            success_rate: 80
                        },
                        {
                            name: "Anlatımı desteklemek için grafik ve tablo kullanır.",
                            true_answers: 1,
                            false_answers: 0,
                            empty_answers: 0,
                            success_rate: 80
                        }
                    ]
                },
                {
                    id: 2,
                    name: "Dinleme, İzleme",
                    success_rate: 50,
                    true_answers: 10,
                    false_answers: 15,
                    empty_answers: 5,
                    outcomes: [
                        {
                            name: "Dinlediklerinde/izlediklerinde geçen olayların gelişimi ve sonucu hakkında tahminde bulunur.",
                            true_answers: 1,
                            false_answers: 0,
                            empty_answers: 0,
                            success_rate: 80
                        },
                        {
                            name: "Dinlediklerinde/izlediklerinde geçen bilmediği kelimelerin anlamını tahmin eder.",
                            true_answers: 1,
                            false_answers: 0,
                            empty_answers: 0,
                            success_rate: 80
                        },
                        {
                            name: " Dinlediklerini/izlediklerini özetler.",
                            true_answers: 1,
                            false_answers: 0,
                            empty_answers: 0,
                            success_rate: 80
                        },
                        {
                            name: "Dinledikleri/izlediklerine yönelik sorulara cevap verir.",
                            true_answers: 0,
                            false_answers: 1,
                            empty_answers: 0,
                            success_rate: 0
                        },
                        {
                            name: "Dinlediklerinin/izlediklerinin konusunu tespit eder.",
                            true_answers: 0,
                            false_answers: 1,
                            empty_answers: 0,
                            success_rate: 0
                        },
                        {
                            name: "Dinlediklerinin/izlediklerinin ana fikrini/ana duygusunu tespit eder.",
                            true_answers: 0,
                            false_answers: 1,
                            empty_answers: 0,
                            success_rate: 0
                        },
                        {
                            name: "Dinlediklerine/izlediklerine yönelik farklı başlıklar önerir.",
                            true_answers: 0,
                            false_answers: 1,
                            empty_answers: 0,
                            success_rate: 0
                        },
                        {
                            name: "Dinlediği/izlediği hikâye edici metinleri canlandırır.",
                            true_answers: 0,
                            false_answers: 1,
                            empty_answers: 0,
                            success_rate: 0
                        },
                        {
                            name: "Dinlediklerinde/izlediklerinde tutarlılığı sorgular.",
                            true_answers: 0,
                            false_answers: 1,
                            empty_answers: 0,
                            success_rate: 0
                        },
                        {
                            name: "Dinledikleriyle/izledikleriyle ilgili görüşlerini bildirir.",
                            true_answers: 0,
                            false_answers: 1,
                            empty_answers: 0,
                            success_rate: 0
                        }
                    ]
                },
                {
                    id: 3,
                    name: "Akıcı Okuma",
                    success_rate: 50,
                    true_answers: 0,
                    false_answers: 15,
                    empty_answers: 5,
                    outcomes: [
                        {
                            name: "Noktalama işaretlerine dikkat ederek sesli ve sessiz okur.",
                            true_answers: 0,
                            false_answers: 1,
                            empty_answers: 0,
                            success_rate: 0
                        }
                    ]
                }
            ]
        }
    ]
}
